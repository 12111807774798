import React, { useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';

import Layout from 'components/layout';
import SEO from 'components/seo';
import { device } from 'components/device';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import Components from 'components/CmsComponents/';
import SocialShare from 'components/units/unitPages/details/socialShare';

import { HeaderContext } from 'context/header';

const RecipeContentContainer = styled.section`
  width: 100%;
  max-width: 73.125rem;
  margin: 4.375rem auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.875rem;

  @media ${device.laptop} {
    position: relative;
    padding: 1.25rem;
    margin: 0;
    display: inline-block;
    grid-template-columns: unset;
    padding-top: 5rem;
  }
`;

const RecipeContent = styled(ReactMarkdown)`
  font-size: 1rem;
  line-height: 1.625rem;
  color: #231f20;

  @media ${device.laptop} {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  h3 {
    font-size: 1rem;
    line-height: 1.625rem;
    font-weight: 600;
    color: #231f20;
    margin: 1.875rem 0 0;

    &:first-of-type {
      margin: 0;
    }

    @media ${device.laptop} {
      font-size: 0.875rem;
      line-height: 1.375rem;
      margin: 1.25rem 0 0;

      &:first-of-type {
        margin: 0;
      }
    }
  }

  ol {
    list-style: none;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.875rem;

    @media ${device.laptop} {
      grid-template-columns: 1fr;
      gap: 1.25rem;
    }

    li {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &::before {
        content: '';
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        background: #c6c8cc;
        border-radius: 50%;
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;

    li {
      margin: 0;
    }
  }

  table {
    width: 100%;
    max-width: calc(100% - 9.375rem);
    margin: 1.875rem auto 0;

    @media ${device.laptop} {
      max-width: unset;
      margin: 1.25rem auto 0;
    }

    thead {
      font-size: 1rem;
      line-height: 1.625rem;
      font-weight: 600;
      color: #231f20;

      @media ${device.laptop} {
        font-size: 0.875rem;
        line-height: 1.375rem;
      }

      th {
        padding: 0;
        border: 0;
        border-bottom: 1px solid #c6c8cc;
      }
    }

    td {
      font-size: 1rem;
      line-height: 1.625rem;
      color: #231f20;

      padding: 0;
      border: 0;

      @media ${device.laptop} {
        font-size: 0.875rem;
        line-height: 1.375rem;
      }
    }
  }
`;

const WrapRecipeSecondContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapRecipeContent = styled.div`
  margin-top: 1.875rem;

  @media ${device.laptop} {
    margin-top: 1.25rem;
  }
`;

const WrapSocialShare = styled.div`
  align-self: center;

  @media ${device.laptop} {
    align-self: unset;
    position: absolute;
    top: 1.75rem;
    left: 1.25rem;
  }
`;

const ContainerTable = styled.div`
  width: 100%;

  @media ${device.laptop} {
    overflow-x: scroll;
  }
`;

const Recipes = ({
  data: {
    gcms: { receitas },
  },
  location,
  pageContext,
}) => {
  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);
  const page = receitas?.[0];
  const formattedRecipeType = page?.receitasTipos?.[0]?.title ?? '';
  const breadcrumbLength = page?.featuredMarketing?.breadcrumb
    .split(/\d./gm)
    .filter(e => e).length;
  const formattedBreadcrumb = `${page?.featuredMarketing?.breadcrumb} ${
    formattedRecipeType &&
    '› \n' + (breadcrumbLength + 1) + '. ' + formattedRecipeType
  } ${
    page?.featuredMarketing?.title &&
    '› \n' + (breadcrumbLength + 2) + '. ' + page?.featuredMarketing?.title
  }`;
  const featuredMarketingContent = {
    ...page?.featuredMarketing,
    breadcrumb: formattedBreadcrumb,
  };
  const components = page?.components ?? [];
  const contentRecipeCategory = page?.receitasCategorias.map(
    (recipeCategory, index) => {
      return recipeCategory?.titulo && `${index + 1}. ${recipeCategory.titulo}`;
    }
  );
  const contentIngredients = page?.ingredientes;
  const contentAdditionalIngredients = page?.ingredientesAdicionais;
  const contentModeOfPreparation = page?.modoDePreparo;
  const contentProduce = page?.rendimento;
  const contentRecipeProduce = page?.receitasRendimentos.map(recipeProduce => {
    return recipeProduce?.rendimento && `- ${recipeProduce.rendimento}`;
  });
  const contentPortion = page?.porcao.map(recipePortion => {
    return recipePortion && `- ${recipePortion}`;
  });
  const contentFeaturedFunctionalFood = page?.destaqueAlimentoFuncional;
  const contentTip = page?.dica;

  const contentFirst = [
    contentRecipeCategory,
    contentIngredients,
    contentAdditionalIngredients,
    contentModeOfPreparation,
  ]
    .flat(Infinity)
    .filter(el => el)
    .join('\n\n');

  const contentSecond = [
    contentProduce,
    contentRecipeProduce,
    contentPortion,
    contentFeaturedFunctionalFood,
    contentTip,
  ]
    .flat(Infinity)
    .filter(el => el)
    .join('\n\n');

  const dataSeo = [
    {
      seo: {
        metaTitle: page?.seo?.metaTitle ?? '',
        metaDescription: page?.seo?.metaDescription ?? '',
      },
    },
  ];

  const tableRender = ({ node, ...props }) => {
    return (
      <ContainerTable>
        <table {...props}></table>
      </ContainerTable>
    );
  };

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <Layout>
      <SEO
        dataSeo={dataSeo}
        image={page?.featuredMarketing?.images?.[0]?.url ?? ''}
      />
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <RecipeContentContainer>
        <RecipeContent
          plugins={[remarkGfm]}
          renderers={{
            table: tableRender,
          }}
        >
          {contentFirst}
        </RecipeContent>
        <WrapRecipeSecondContent>
          <WrapSocialShare>
            <SocialShare
              location={location}
              title={featuredMarketingContent?.title ?? ''}
              isBlogPost
              containerPaddingMobile="0"
              shareBoxFlexGap="0.625rem"
              iconsBoxMargin="0"
              iconsBoxMarginMobile="0"
            />
          </WrapSocialShare>
          <WrapRecipeContent>
            <RecipeContent
              plugins={[remarkGfm]}
              renderers={{
                table: tableRender,
              }}
            >
              {contentSecond}
            </RecipeContent>
          </WrapRecipeContent>
        </WrapRecipeSecondContent>
      </RecipeContentContainer>
      {components.length >= 1 &&
        components?.map(component => {
          return Components(component);
        })}
    </Layout>
  );
};

export const RecipePageQuery = graphql`
  query recipePageQuery($id: ID) {
    gcms {
      receitas(locales: [pt_BR, en], where: { id: $id }) {
        id
        title
        slug
        seo {
          id
          metaTitle
          metaDescription
          noIndex
        }
        receitasTemas {
          id
          title
          slug
        }
        receitasTipos {
          id
          title
          slug
        }
        image {
          id
          handle
          url
          width
          height
        }
        receitasCategorias {
          id
          titulo
        }
        ingredientes
        ingredientesAdicionais
        modoDePreparo
        rendimento
        receitasRendimentos {
          id
          rendimento
        }
        porcao
        destaqueAlimentoFuncional
        dica
        featuredMarketing {
          id
          type
          breadcrumb
          backgroundColor {
            hex
          }
          images {
            handle
            width
            height
            url
          }
          title
          fragments {
            id
            name
            type
            markdown
          }
        }
        components {
          __typename
          ... on GraphCMS_SimpleContent {
            id
            name
            type
            title
            fragments {
              id
              name
              type
              markdown
              datafragment
            }
          }
          ... on GraphCMS_HighlightContent {
            id
            name
            type
            backgroundColorInitial {
              hex
            }
            backgroundColorFinal {
              hex
            }
            title
            customTitle
            description
            images {
              handle
              width
              height
              url
            }
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_HighlightFormLeadContent {
            id
            name
            type
            backgroundColorInitial {
              hex
            }
            backgroundColorFinal {
              hex
            }
            title
            customTitle
            description
            referenceToSalesforce
            images {
              handle
              width
              height
              url
            }
            documentsForDownload {
              handle
              width
              height
              url
            }
            footnote
          }
          ... on GraphCMS_VideoGalleryContent {
            id
            name
            type
            title
            headerLinkTitle
            headerLink
            description
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_BannerSpecialtyContent {
            id
            name
            type
            backgroundColor {
              hex
            }
            title
            description
            images {
              handle
              width
              height
              url
            }
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_ListPostContent {
            id
            name
            type
            title
            headerLinkTitle
            headerLink
            posts {
              id
              slugPost
              title
              topic
              author
              doctors(first: 1000, locales: [pt_BR, en]) {
                id
                id_api
                active
                assignment
                name
              }
              assetpicker {
                handle
                height
                width
                url
              }
              categories {
                tag
              }
            }
          }
          ... on GraphCMS_DoctorAndSpecialtyContent {
            id
            name
            type
            title
            subtitle
            estado
            images {
              handle
              width
              height
              url
            }
            specialties {
              id
              name
              slug
              customUrl
              unity
            }
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_CarouselGalleryContent {
            id
            name
            type
            title
            backgroundColor {
              hex
            }
            images {
              handle
              width
              height
              url
            }
          }
        }
      }
    }
  }
`;

export default Recipes;
